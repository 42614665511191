@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
*{
    box-sizing: border-box;
}
body{
    background-color: #111;
    color: #fff;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.lists{
    margin-top: -110px;
}
footer{
    margin:50px 0;
    text-align: center;
}
.loading{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: black;
    display: center;
    justify-content: center;
    align-items: center;
}
.loading img{
    width: 100%;
    height: 100%;
}

.movieRow{
    margin-bottom: 30px;
}
h2{
    margin: 0px 0px 0px 30px;
}
.movieRow--listarea{
    overflow-x: hidden;
    padding-left: 30px;
}
.movieRow--list{
    transition: all ease 0.3s;
}
.movieRow--item{
    display: inline-block;
    width: 150px;
}
.movieRow--item img{
    width: 100%;
    height: 205.5px;
    transform: scale(0.9);
    transition: all ease 0.2s;
    cursor:pointer;
}
.movieRow--item img:hover{
    transform:scale(1.0);
}
.movieRow--left, 
.movieRow--right{
    position: absolute;
    width: 40px;
    height: 225px;
    background-color: rgba(0, 0, 0,0.6);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.3s;
}
.movieRow--right{
    right:0;
}
.movieRow:hover .movieRow--left,
.movieRow:hover .movieRow--right{
    opacity: 1;
}
@media(max-width:760px){
    .movieRow--left,
    .movieRow--right{
        opacity:1;
    }
}
.featured{
    height: 100vh;
}
.featured--vertical{
    width:inherit;
    height: inherit;
    background: linear-gradient(to top, #111 10%, transparent);
}
.featured--horizontal{
    width:inherit;
    height: inherit;
    background: linear-gradient(to right, #111 30%, transparent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-bottom: 150px;
}
.featured--name{
    font-size: 60px;
    font-weight: bold;
}
.featured--info{
    font-size:18px;
    font-weight: bold;
    margin-top: 15px;
}
.featured--points, 
.featured--year, 
.featured--seasons{
    display: inline-block;
    margin-right: 15px;
}
.featured--points{ 
    color: #46d369;    
}
.featured--description{
    margin-top: 15px;
    font-size: 20px;
    color: #999;
    max-width: 40%;
}
.featured--buttons{
    margin-top: 15px;
}
.featured--watchbutton, .featured--mylistbutton{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 12px 25px;
    border-radius: 5%;
    text-decoration: none;
    margin-right: 10px;
    opacity: 1;
    transition: all ease 0.3s;
}
.featured--watchbutton:hover, .featured--mylistbutton:hover{
    opacity: 0.7;
}
.featured--watchbutton{
    background-color: white;
    color: black;
}
.featured--mylistbutton{
    background-color: #333;
    color: white;
}
.featured--genres{
    margin-top: 15px;
    font-size: 15px;
    color: #999;
}
@media(max-width:760px){
    .featured{
        height: 90vh;
    }
    .featured--name{
        font-size: 40px;
    }
    .featured--info{
        font-size: 16px;
    }
    .featured--description{
        font-size: 14px;
        max-width: 100%;
        margin-right: 30px;
    }
    .featured--watchbutton,
    .featured--mylistbutton{
        font-size: 16px;
    }
    .featured--genres{
        font-size: 14px;
    }
}
header{
    position:fixed;
    z-index:999;
    top:0;
    left:0;
    right: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: transparent;
}
header.black{
    background-color: #141414;
}
.header--logo{
    height: 25px;
}
.header--logo img{
    height: 100%;
}
.header--user{
    height: 35px;
}
.header--user img{
    height: 100%;
    border-radius: 3px;
}
