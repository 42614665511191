.movieRow{
    margin-bottom: 30px;
}
h2{
    margin: 0px 0px 0px 30px;
}
.movieRow--listarea{
    overflow-x: hidden;
    padding-left: 30px;
}
.movieRow--list{
    transition: all ease 0.3s;
}
.movieRow--item{
    display: inline-block;
    width: 150px;
}
.movieRow--item img{
    width: 100%;
    height: 205.5px;
    transform: scale(0.9);
    transition: all ease 0.2s;
    cursor:pointer;
}
.movieRow--item img:hover{
    transform:scale(1.0);
}
.movieRow--left, 
.movieRow--right{
    position: absolute;
    width: 40px;
    height: 225px;
    background-color: rgba(0, 0, 0,0.6);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.3s;
}
.movieRow--right{
    right:0;
}
.movieRow:hover .movieRow--left,
.movieRow:hover .movieRow--right{
    opacity: 1;
}
@media(max-width:760px){
    .movieRow--left,
    .movieRow--right{
        opacity:1;
    }
}